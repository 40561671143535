import { useEffect, useMemo } from 'react';
import { onAuthStateChanged } from 'firebase/auth';

import { fetchBillingSubscriptions } from 'api/billing';
import { auth } from 'lib/firebase/firebase-config';
import { clearStorage, getStorageItem } from 'utils/storage';
import { useAuthStore } from 'store/auth';
import { getMe } from 'api/user';
import { reportAnalyticsEvent } from 'utils/analytics';
import { useModalsStore } from 'store/modals';
import Cookies from 'js-cookie';
import { showToast } from 'components/ui/CustomToast';
import { isCrushAppMobile, isCrushAppIOS, isCrushAppAndroid } from 'utils/userAgentCheck';

export const useAuth = () => {
  const { user, setUser, setIsLoading, setIsLogged } = useAuthStore();
  const { setIsOpenAuthModal } = useModalsStore();

  const subscribeToAuthChanges = () =>
    onAuthStateChanged(auth, async (firebaseUser) => {
      try {
        setIsLoading(true);
        const isAppMobile = isCrushAppMobile();

        if (firebaseUser) {
          setIsLogged(true);
          const user = await getMe();
          Cookies.set('token', await firebaseUser.getIdToken());
          try {
            window.posthog.identify(user?.uuid, { email: user?.email, name: user?.custom_attributes.name });
          } catch (error) {}
          await fetchBillingSubscriptions();
        } else {
          if (!isAppMobile && getStorageItem('user')) {
            const { logoutRedirect } = useAuthStore.getState();
            if (logoutRedirect) {
              showToast({ message: 'You have now signed out.', type: 'success' });
              reportAnalyticsEvent('Logout Completed');
              window.location.href = logoutRedirect || '/';
            } else {
              setIsOpenAuthModal(true);
            }

            setUser(null);
            setIsLogged(false);
            clearStorage();
            Cookies.remove('userID');
            Cookies.remove('token');
            window.posthog.reset();
          }
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    });

  useEffect(() => {
    // Set user from local storage
    const user = getStorageItem('user');
    if (user) setUser(user);

    // Subscribe to auth changes
    const unsubscribe = subscribeToAuthChanges();
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isUserDetailsPopulated = useMemo(() => {
    if (!user) return false;

    const { custom_attributes } = user;

    return Boolean(custom_attributes?.gender) &&
      Boolean(custom_attributes?.name) &&
      Boolean(custom_attributes?.accept_tos_18)
      ? false
      : true;
  }, [user]);

  return {
    isUserDetailsPopulated
  };
};
