import { IconProps } from 'utils/global.types';

export const Reddit = (props?: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path fill="currentColor" d="M6 9.5l-.5-.001V9.5zm4.5 0h.5l-.5-.001z"></path>
    <path
      fill="currentColor"
      d="M16.07 8c0-1.103-.897-2-2-2-.441 0-.86.144-1.202.405-1.116-.775-2.578-1.237-4.133-1.354l.839-1.968 2.441.57C12.093 4.408 12.725 5 13.5 5c.827 0 1.5-.673 1.5-1.5S14.327 2 13.5 2c-.524 0-.985.271-1.253.68l-2.853-.667a.5.5 0 00-.575.291l-1.16 2.72c-1.679.058-3.279.531-4.487 1.355A2.002 2.002 0 000 8a1.98 1.98 0 001.011 1.731A2.978 2.978 0 001 10c0 2.757 3.14 5 7 5 3.859 0 7-2.243 7-5 0-.078-.003-.155-.008-.232A1.98 1.98 0 0016.07 8zM13.5 3a.5.5 0 11-.002 1.002A.5.5 0 0113.5 3zm-9 6.5c0-.551.449-1 1-1s1 .449 1 1a1.001 1.001 0 01-2 0zm5.809 3.317a3.936 3.936 0 01-4.618 0 .5.5 0 11.586-.81 2.933 2.933 0 003.446 0 .5.5 0 11.586.81zM10.5 10.5a1 1 0 110-2 1 1 0 010 2z"
    ></path>
  </svg>
);
