import { useCallback, useMemo, useRef } from 'react';
import useWebSocket from 'react-use-websocket';
import Bugsnag from '@bugsnag/js';

import { Attachment, MessageInConversation } from 'api/conversation/types';
import { reportAnalyticsEvent } from 'utils/analytics';
import { setStorageItem } from 'utils/storage';
import { reactQuery } from 'lib/reactQuery';
import { useAuthStore } from 'store/auth';
import { useChatStore } from 'store/chat';
import { useRouter } from 'next/router';
import { User } from 'api/user/types';
import { socketURL } from 'api/user';
import { showToast } from 'components/ui/CustomToast';

export const useSocketChat = () => {
  const connectionOpened = useRef(false);

  const { query } = useRouter();
  const account_id = useMemo(() => (query?.id as string) || '', [query]);

  const { addMessageToChat, updatePhoto, updateUnread, updateIsDisabledReqPhoto, updateTyping } = useChatStore();
  const { user, setUser } = useAuthStore();

  const updateChat = useCallback((msg: MessageInConversation) => addMessageToChat(msg), []);

  const handleNewMessage = useCallback(
    (data: any) => {
      const msg: MessageInConversation = {
        id: data.id,
        content: data.content,
        content_attributes: data.content_attributes,
        created_at: data.created_at,
        message_type: data.message_type,
        sender_id: data.sender_id,
        sender_type: data.sender_type,
        status: data.status,
        attachments: data.attachments,
        account_id: data.account_id,
        conversation_real_id: data.conversation_real_id
      };

      if (msg.sender_type === 'Contact') {
        reportAnalyticsEvent('Message Sent', { type: 'text', bot_account_id: msg.account_id });
        setTimeout(() => updateTyping(msg.account_id, true), 1000);
      } else {
        updateTyping(msg.account_id, false);
      }

      if (+account_id !== msg.account_id) updateUnread(msg.account_id, true);
      if (msg?.attachments?.length) {
        updateIsDisabledReqPhoto(msg.account_id, false);
        reactQuery.refetchQueries(['botGallery', msg.conversation_real_id]);
      }

      updateChat(msg);
      reactQuery.refetchQueries(['all-conversations']);
    },
    // eslint-disable-next-line
    [query]
  );

  const handleUpdatedMessage = useCallback(
    (data: any) => {
      const account_id = data.account_id;
      const photoAttachment = data.attachments.find((i: any) => i.file_type === 'image');
      if (!photoAttachment) return;

      const photo: Attachment = {
        cost: photoAttachment.cost,
        created_at: photoAttachment.created_at,
        data_url: photoAttachment.data_url,
        level: photoAttachment.level,
        file_type: photoAttachment.file_type,
        unlocked_at: photoAttachment.unlocked_at,
        deleted_at: photoAttachment.deleted_at,
        user_media_id: photoAttachment.user_media_id,
        media_asset_id: photoAttachment.media_asset_id
      };

      if (+account_id !== account_id) updateUnread(account_id, true);
      updatePhoto(account_id, { ...photo, messageId: data.id });
    },
    // eslint-disable-next-line
    [query]
  );

  const onMessage = useCallback(
    (message: MessageEvent<any>) => {
      const json = JSON.parse(message.data);
      const event = json?.message?.event;
      const data = json?.message?.data;

      if (event === 'balance.updated') {
        const newUser = { ...(user as User), credit_balance: data.balance };
        setUser(newUser);
        setStorageItem('user', newUser);
      } else if (event === 'message.created') {
        handleNewMessage(data);
      } else if (event === 'message.updated') {
        handleUpdatedMessage(data);
      }
    },
    // eslint-disable-next-line
    [user, query]
  );

  const onOpen = useCallback(() => {
    if (user?.pubsub_token && !connectionOpened.current) {
      sendMessage(
        JSON.stringify({
          command: 'subscribe',
          identifier: { channel: 'GlobalUser', pubsub_token: user.pubsub_token }
        })
      );
      connectionOpened.current = true;
    }
  }, [user, connectionOpened]);

  const { sendMessage } = useWebSocket(socketURL, {
    onMessage,
    onOpen,
    onError: (error: Event) => {
      console.error('WebSocket encountered an error:', error);
      connectionOpened.current = false;
    },
    onClose: () => {
      console.log('Socket closed');
      connectionOpened.current = false;
    },
    onReconnectStop: () => {
      Bugsnag.notify(`WebSocket reconnect failed for user ${user?.id}`);

      showToast({
        message: 'Disconnected from Crush',
        type: 'error',
        button: (
          <button type="button" onClick={() => window.location.reload()} className="text-sm text-brand">
            Refresh
          </button>
        ),
        options: {
          autoClose: false
        }
      });
    },
    shouldReconnect: () => true,
    reconnectAttempts: 20,
    reconnectInterval: (attempt) => Math.min(Math.pow(2, attempt) * 1000, 30000),
    retryOnError: true,
    heartbeat: {
      message: JSON.stringify({ command: 'pong' }),
      interval: 20000
    },
    share: true
  });
};
