import { useEffect, useState } from 'react';
import { isCrushAppAndroid, isCrushAppIOS, isCrushAppMobile } from 'utils/userAgentCheck';

export const useIsAppMobile = () => {
  const [isAppMobile, setIsAppMobile] = useState(false);
  const [isAppIOS, setIsAppIOS] = useState(false);
  const [isAppAndroid, setIsAppAndroid] = useState(false);

  useEffect(() => {
    setIsAppMobile(isCrushAppMobile());
    setIsAppIOS(isCrushAppIOS());
    setIsAppAndroid(isCrushAppAndroid());
  }, []);

  return { isAppMobile, isAppIOS, isAppAndroid };
};
