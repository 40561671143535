import { IconProps } from 'utils/global.types';

export const Logo = (props?: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="30" fill="none" viewBox="0 0 530 200" {...props}>
    <g>
      <path
        fill="#fff"
        strokeWidth="0px"
        d="m91.3,87.1h-38.63v-33.17c0-9.64-.41-15.66-1.24-18.05-.83-2.39-2.65-3.59-5.46-3.59-3.18,0-5.2,1.45-6.06,4.36-.86,2.91-1.28,9.19-1.28,18.86v88.64c0,9.27.43,15.32,1.28,18.15.86,2.83,2.78,4.24,5.78,4.24s4.76-1.42,5.64-4.25c.89-2.84,1.33-9.49,1.33-19.97v-23.99h38.63v7.44c0,19.75-1.09,33.76-3.26,42.02-2.17,8.26-6.97,15.5-14.41,21.72-7.43,6.22-16.59,9.32-27.48,9.32s-20.65-2.63-27.99-7.9-12.2-12.55-14.59-21.87c-2.39-9.31-3.58-23.32-3.58-42.02v-55.75c0-13.75.37-24.06,1.1-30.94.73-6.88,2.92-13.5,6.56-19.86,3.64-6.36,8.69-11.37,15.14-15.03C29.26,1.83,36.67,0,45.05,0c11.38,0,20.77,2.83,28.17,8.48,7.4,5.65,12.26,12.69,14.59,21.13,2.32,8.44,3.49,21.56,3.49,39.37v18.13Z"
      />
      <path
        fill="#fff"
        strokeWidth="0px"
        d="m90.5,4.01h27.34c18.23,0,30.57.9,37.02,2.71,6.45,1.81,11.71,6.42,15.78,13.85,4.07,7.43,6.1,19.27,6.1,35.54,0,14.85-1.44,24.83-4.31,29.94-2.88,5.11-8.53,8.17-16.98,9.19,7.65,2.44,12.78,5.7,15.42,9.78,2.63,4.09,4.27,7.84,4.91,11.26.64,3.42.96,12.83.96,28.23v50.33h-35.88v-63.41c0-10.21-.63-16.54-1.88-18.98-1.25-2.43-4.54-3.65-9.86-3.65v86.04h-38.63V4.01Zm38.63,32.65v42.43c4.34,0,7.39-.77,9.13-2.3,1.74-1.53,2.62-6.5,2.62-14.91v-10.49c0-6.05-.84-10.02-2.52-11.9-1.68-1.89-4.76-2.83-9.22-2.83Z"
      />
      <path
        fill="#fff"
        strokeWidth="0px"
        d="m264.86,4.01v127.53c0,14.46-.37,24.62-1.1,30.47-.73,5.86-2.91,11.87-6.52,18.03-3.61,6.17-8.37,10.84-14.27,14.03-5.9,3.18-12.86,4.77-20.88,4.77-8.87,0-16.7-1.89-23.49-5.66-6.79-3.77-11.87-8.68-15.23-14.73-3.36-6.05-5.35-12.44-5.96-19.15-.61-6.72-.92-20.84-.92-42.37V4.01h38.63v143.09c0,8.33.35,13.65,1.06,15.97.7,2.32,2.12,3.48,4.27,3.48,2.45,0,4.02-1.28,4.73-3.83.7-2.55,1.06-8.58,1.06-18.09V4.01h38.63Z"
      />
      <path
        fill="#fff"
        strokeWidth="0px"
        d="m347.01,61.76h-35.88v-14.14c0-6.6-.46-10.8-1.38-12.61-.92-1.81-2.45-2.71-4.59-2.71-2.33,0-4.08,1.22-5.28,3.66-1.19,2.44-1.79,6.13-1.79,11.09,0,6.37.67,11.17,2.01,14.39,1.28,3.23,4.92,7.11,10.92,11.67,17.2,13.11,28.03,23.87,32.49,32.28,4.46,8.41,6.7,21.96,6.7,40.66,0,13.6-1.24,23.61-3.72,30.06-2.48,6.44-7.26,11.85-14.36,16.21-7.1,4.36-15.35,6.54-24.77,6.54-10.34,0-19.16-2.52-26.47-7.54-7.31-5.03-12.1-11.43-14.36-19.21-2.26-7.78-3.4-18.82-3.4-33.12v-12.49h35.88v23.22c0,7.15.5,11.75,1.51,13.79,1.01,2.04,2.8,3.07,5.37,3.07s4.48-1.3,5.74-3.89c1.25-2.59,1.88-6.44,1.88-11.55,0-11.24-1.19-18.58-3.58-22.04-2.45-3.46-8.47-9.23-18.08-17.33-9.6-8.17-15.97-14.1-19.09-17.8-3.12-3.69-5.7-8.8-7.75-15.32-2.05-6.52-3.07-14.85-3.07-24.99,0-14.62,1.45-25.3,4.36-32.06,2.9-6.76,7.6-12.04,14.08-15.85,6.48-3.81,14.31-5.72,23.49-5.72,10.03,0,18.58,2.08,25.65,6.25,7.07,4.17,11.75,9.41,14.04,15.74,2.29,6.33,3.44,17.07,3.44,32.24v7.54Z"
      />
      <path
        fill="#fff"
        strokeWidth="0px"
        d="m436.5,4.01v190.83h-38.63v-80.15h-11.56v80.15h-38.63V4.01h38.63v68.25h11.56V4.01h38.63Z"
      />
    </g>
  </svg>
);
