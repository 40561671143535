import { getFormikError } from 'utils';
import { Tooltip } from 'react-tooltip';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  formik?: any;
  inputClassName?: string;
  className?: string;
  tooltip?: string;
  maxLength?: number;
};

export const Input = ({ label, formik, className, inputClassName, tooltip, maxLength, ...rest }: Props) => {
  const error = getFormikError(formik, rest.name);
  const currentLength = (formik ? formik.values[rest.name as any] : rest.value as string)?.length || 0;

  return (
    <div className={`input-field relative ${className ? className : ''} `}>
      {!rest.hidden && (
        <div className="flex items-center justify-between ml-1">
          <div className="flex items-center gap-2">
            <label>{label}</label>
            {tooltip && (
              <>
                <Tooltip id={`${rest.name}-tooltip`} />
                <span
                  className="text-muted-foreground cursor-help text-stone-400 text-sm"
                  data-tooltip-id={`${rest.name}-tooltip`}
                  data-tooltip-content={tooltip}
                >
                  ⓘ
                </span>
              </>
            )}
          </div>
          {maxLength && (currentLength > maxLength * 0.8) && (
            <span className={`text-xs ${currentLength > maxLength ? 'text-red-500' : 'text-stone-500'}`}>
              {currentLength}/{maxLength}
            </span>
          )}
        </div>
      )}
      <input
        {...rest}
        maxLength={maxLength}
        {...(formik ? { onChange: formik.handleChange, value: formik.values[rest.name as any] } : {})}
        className={inputClassName}
      />
      {error && <p className="error-msg">{error}</p>}
    </div>
  );
};