import { IconProps } from 'utils/global.types';

export const Close = (props?: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" fill="none" viewBox="0 0 20 20" {...props}>
    <g clipPath="url(#clip0_2468_10400)">
      <path
        fill="currentColor"
        d="M15.25 4.758a.83.83 0 00-1.175 0L10 8.825 5.925 4.75A.83.83 0 104.75 5.925L8.825 10 4.75 14.075a.83.83 0 101.175 1.175L10 11.175l4.075 4.075a.83.83 0 101.175-1.175L11.175 10l4.075-4.075a.835.835 0 000-1.167z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_2468_10400">
        <path fill="currentColor" d="M0 0H20V20H0z"></path>
      </clipPath>
    </defs>
  </svg>
);
