import Link from 'next/link';
import { useEffect, useState } from 'react';

import dayjs from 'utils/dayjs.config';
import { useModalsStore } from 'store/modals';
import { ModalWrapper } from './ModalWrapper';
import { useDisableBack } from 'hooks/useDisableBack';
import { Close } from 'components/icons/Close';

export const UpsellModal = () => {
  const { upsellModal, setUpsellModal } = useModalsStore();
  const [timeLeft, setTimeLeft] = useState('');

  const onClose = () => setUpsellModal({ image: '', type: null });

  useDisableBack({ onClick: onClose });

  const updateTimer = (targetTime: dayjs.Dayjs, intervalId?: NodeJS.Timeout) => {
    const now = dayjs.utc();
    const difference = targetTime.diff(now);

    if (difference <= 0) {
      if (intervalId) clearInterval(intervalId);
      setTimeLeft('00:00:00');
    } else {
      const duration = dayjs.duration(difference);
      setTimeLeft(
        `${String(duration.hours()).padStart(2, '0')}:${String(duration.minutes()).padStart(2, '0')}:${String(duration.seconds()).padStart(2, '0')}`
      );
    }
  };

  useEffect(() => {
    const targetTime = dayjs.utc().startOf('day').add(1, 'day');

    updateTimer(targetTime);

    const intervalId = setInterval(() => {
      updateTimer(targetTime, intervalId);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <ModalWrapper onClose={onClose}>
      <div className="cy-upsell-modal bg-secondary w-full lg:w-[800px] border border-zinc-700 rounded-xl flex flex-col lg:flex-row items-center overflow-hidden relative">
        <Close className="absolute top-3 right-3 cursor-pointer" onClick={onClose} width={25} height={25} />

        <div
          className="w-full lg:w-[385px] md:h-[440px] h-[280px]"
          style={{
            backgroundImage: `url(${upsellModal.image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'top'
          }}
        />
        <div className="p-6 lg:p-10 flex-1">
          {upsellModal.type === 'premium_profile' ? (
            <>
              <h2 className="text-2xl">
                <span className="text-brand">Upgrade to premium</span> to unlock NSFW features.
              </h2>
              <div className="text-stone-400 mt-3 mb-10 lg:text-base">
                <p>Experience the best Crush has to offer with unlimited messages, NSFW photos, and spicy features.</p>
                <br />
                <p>Let Crush cater to your every fantasy.</p>
              </div>
            </>
          ) : upsellModal.type === 'premium_photo' ? (
            <>
              <h2 className="text-2xl">
                <span className="text-brand">Like what you saw?</span> Get the sexiest uncensored photos.
              </h2>
              <div className="text-stone-400 mt-3 mb-10 lg:text-base">
                <p>Join Premium for more nude and hardcore photos, unlimited messages, and many more NSFW features.</p>
                <br />
                <p>Let Crush cater to your every fantasy.</p>
              </div>
            </>
          ) : upsellModal.type === 'premium_voice' ? (
            <>
              <h2 className="text-2xl">
                <span className="text-brand">Just Hit Play!</span>
              </h2>
              <div className="text-stone-400 mt-3 mb-10 lg:text-base">
                <p>Free users are limited to voice messages under 10s.</p>
                <br />
                <p>
                  {' '}
                  Join Premium for unrestricted voice messages. Get unlimited messages, spicy photos, and many more
                  NSFW features.
                </p>
                <br />
                <p> Come on, babe. Join me at the VIP club.</p>
              </div>
            </>
          ) : (
            <>
              <h2 className="text-2xl">
                <span className="text-brand">Upgrade to premium</span> to unlock the VIP treatment.
              </h2>
              <div className="text-stone-400 mt-3 mb-10 lg:text-base">
                <p>Experience the best Crush has to offer with unlimited messages, spicy photos, and spicy features.</p>
                <br />
                <p>Or come back tomorrow for daily free credits.</p>
              </div>
            </>
          )}

          <Link href="/subscriptions" className="btn contained primary" onClick={onClose} prefetch={false}>
            Upgrade to Premium
          </Link>

          {upsellModal.showRefreshCountdown && (
            <p className="text-center text-sm text-stone-400 mt-2">{timeLeft} until new credits</p>
          )}
        </div>
      </div>
    </ModalWrapper>
  );
};
