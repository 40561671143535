import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

const usersTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

dayjs.tz.setDefault(usersTimezone);

export const transformToUserTZ = (date: string | dayjs.Dayjs) => dayjs.utc(date).tz(usersTimezone);
export const transformUnixToUserTZ = (date: number) => dayjs.unix(date).utc().tz(usersTimezone);
export const getUnix = (str: string | number) => dayjs(str).valueOf();

export const parseDate = (date: string | number | dayjs.Dayjs) =>
  typeof date === 'number' ? transformUnixToUserTZ(date) : transformToUserTZ(date);

export default dayjs;
