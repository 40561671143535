import { create } from 'zustand';

import { MessageInConversation, Attachment } from 'api/conversation/types';
import { scrollToBottom } from 'hooks/useChatScroll';

export type Chat = {
  conversationId: number;
  account_id: number; // id of the bot
  messages: MessageInConversation[];
  unread?: boolean;
  isTyping?: boolean;
  isDisabledReqPhoto?: boolean;
  isDisabledSurpriseMessage?: boolean;
  isKeyboardOpen?: boolean;
};

type ChatStoreState = {
  isKeyboardOpen?: boolean;
  isVisibleConversations: boolean;
  chats: Chat[];
  setIsVisibleConversations: (isVisible: boolean) => void;
  addChat: (chat: Chat) => void;
  removeChat: (account_id: number) => void;
  addMessageToChat: (message: MessageInConversation) => void;
  loadChatHistory: (conversationId: number, messages: MessageInConversation[]) => void;
  updatePhoto: (account_id: number, photoData: Attachment & { messageId: number }) => void;
  updateUnread: (account_id: number, status: boolean) => void;
  updateTyping: (account_id: number, status: boolean) => void;
  updateIsDisabledReqPhoto: (account_id: number, status: boolean) => void;
  updateIsDisabledSurpriseMessage: (account_id: number, status: boolean) => void;
  setIsKeyboardOpen: (isOpen: boolean) => void;
};

export const useChatStore = create<ChatStoreState>((set) => ({
  isKeyboardOpen: false,
  setIsKeyboardOpen: (isKeyboardOpen: boolean) => set((p) => ({ ...p, isKeyboardOpen })),

  isVisibleConversations: true,
  setIsVisibleConversations: (isVisible) => set({ isVisibleConversations: isVisible }),

  chats: [],

  removeChat: (account_id: number) => set((p) => ({ ...p, chats: p.chats.filter((c) => c.account_id !== account_id) })),

  addChat: (chat) =>
    set((p) => {
      const isIn = p.chats.some((c) => c.account_id === chat.account_id && c.conversationId === chat.conversationId);

      if (isIn) {
        return {
          ...p,
          chats: p.chats.map((c) =>
            c.account_id === chat.account_id && c.conversationId === chat.conversationId
              ? { ...c, messages: chat.messages }
              : c
          )
        };
      }

      return { ...p, chats: [...p.chats, { ...chat, unread: false }] };
    }),

  addMessageToChat: (message) =>
    set((p) => {
      const chat = p.chats.find(
        (c) => c.account_id === message.account_id && c.conversationId === message.conversation_real_id
      );

      const isIn = chat?.messages.some((m) => m.id === message.id);

      if (chat && !isIn) {
        chat.messages.push(message);

        // When a message is inserted into the Chat, check:
        // if `photo_generating` == true, disable photo button
        // else if contains photo, enable photo button
        // (later) else check for user's active generation to disable active account's photo button.
        if (message.message_type === 1 && message.content_attributes) {
          var set_disabled: boolean | null = null; // Default to null
          if (message.content_attributes.photo_generating === true) {
            set_disabled = true;
          }
          else if (message.content_attributes.user_media_id !== null) {
            set_disabled = false;
          }

          // Only execute set(...) if set_disabled is not null
          if (set_disabled !== null) {
            set((p) => {
              p.updateIsDisabledReqPhoto(
                message.account_id,
                set_disabled as boolean
              );
              return p;
            });
          }
        }
      }

      setTimeout(() => scrollToBottom('smooth'), 500);
      return { ...p, chats: p.chats };
    }),

  loadChatHistory: (conversationId, messages) =>
    set((p) => {
      const chat = p.chats.find((c) => c.conversationId === conversationId);
      const filteredMessages = messages.filter(({ id }) => !chat?.messages.find((cm) => cm.id === id));
      if (chat) {
        const newMessages = [...filteredMessages, ...chat.messages];
        chat.messages = newMessages;
      }
      return { ...p, chats: p.chats };
    }),

  updatePhoto: (account_id, photoData) =>
    set((p) => {
      const chat = p.chats.find((c) => c.account_id === account_id);

      if (chat) {
        const message = chat.messages.find(({ id }) => id === photoData.messageId);

        if (message) {
          message.attachments = message.attachments?.map((a) =>
            a.user_media_id === photoData.user_media_id
              ? { ...a, data_url: photoData.data_url, unlocked_at: photoData.unlocked_at }
              : a
          );
        }
      }

      return { ...p, chats: p.chats };
    }),

  updateUnread: (account_id, status) =>
    set((p) => {
      const chat = p.chats.find((c) => c.account_id === account_id);
      if (chat) chat.unread = status;
      return { ...p, chats: p.chats };
    }),

  updateIsDisabledReqPhoto: (account_id, status) =>
    set((p) => {
      const chat = p.chats.find((c) => c.account_id === account_id);
      if (chat) chat.isDisabledReqPhoto = status;
      return { ...p, chats: p.chats };
    }),

  updateTyping: (account_id, status) =>
    set((p) => {
      const chat = p.chats.find((c) => c.account_id === account_id);
      if (chat) chat.isTyping = status;
      return { ...p, chats: p.chats };
    }),

  updateIsDisabledSurpriseMessage: (account_id, status) =>
    set((p) => {
      const chat = p.chats.find((c) => c.account_id === account_id);
      if (chat) chat.isDisabledSurpriseMessage = status;
      return { ...p, chats: p.chats };
    })
}));
