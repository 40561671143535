import axios from 'axios';
import { getStorageItem } from './storage';
import Bugsnag from '@bugsnag/js';

export const reportAnalyticsEvent = (event: string, properties?: any) => {
  try {
    gtag('event', event, properties);
    window.posthog.capture(event, properties);
    if (window.location.host !== 'www.crush.to')
      console.log(`Event captured: ${event}\n${JSON.stringify(properties, null, 2)}`);
  } catch (err: any) {}
};

export const reportJuicyAds = async (amount: number) => {
  const jaid = getStorageItem('jaid');
  if (jaid) {
    try {
        await axios.get(
          `https://ck.juicyads.com/ilikeitjuicy.php?u=199147&s2s=${jaid}&amount=${amount}&token=beb4c12af91d7c438dd20c4f4bfebcab&data=2d7b9384fd5922501a0df40c52522456`
        );
    } catch (err: any) {
      Bugsnag.notify(`Error sending data to JuicyAds: jaid: ${jaid}, amount: ${amount} - error: ${err.data}`);
    }
  }
};
