import { IconProps } from 'utils/global.types';

export const Login = (props?: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    fill="currentColor"
    stroke="currentColor"
    strokeWidth="0"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="m12.136,19.103c-.512,0-1.023-.171-1.45-.513-1.401-1.122-4.051-3.853-4.051-6.159,0-1.892,1.458-3.43,3.25-3.43.89,0,1.675.318,2.25.852.575-.533,1.36-.852,2.25-.852,1.792,0,3.25,1.539,3.25,3.43,0,2.307-2.649,5.038-4.05,6.16-.427.342-.938.513-1.449.513Zm-2.251-8.103c-.689,0-1.25.642-1.25,1.43,0,1.276,1.917,3.49,3.301,4.598.118.097.279.097.399,0,1.384-1.108,3.3-3.322,3.3-4.599,0-.789-.561-1.43-1.25-1.43-.622,0-1.25.374-1.25,1.209,0,.552-.447,1-1,1s-1-.448-1-1c0-.893-.674-1.209-1.25-1.209Zm9.115,13H5c-2.757,0-5-2.243-5-5v-9.276c0-1.664.823-3.213,2.203-4.145L9.203.855c1.698-1.146,3.895-1.147,5.595,0l7,4.723c1.379.931,2.202,2.48,2.202,4.145v9.276c0,2.757-2.243,5-5,5ZM12.001,1.998c-.585,0-1.169.172-1.679.516L3.322,7.237c-.828.559-1.322,1.488-1.322,2.487v9.276c0,1.654,1.346,3,3,3h14c1.654,0,3-1.346,3-3v-9.276c0-.999-.494-1.929-1.321-2.487l-7-4.723c-.51-.344-1.094-.516-1.678-.516Z" />
  </svg>
);
