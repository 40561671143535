import { IconProps } from 'utils/global.types';

export const Coin = (props?: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" data-name="Layer 1" viewBox="0 0 24 24" {...props}>
    <path
      fill="white"
      d="M14.5 7c-1 0-1.87.37-2.5.99-.63-.62-1.5-.99-2.5-.99C7.57 7 6 8.66 6 10.7c0 3.26 4.87 6.74 5.43 7.12l.57.4.57-.4c.56-.39 5.43-3.87 5.43-7.12C18 8.66 16.43 7 14.5 7zM12 15.76c-1.74-1.33-4-3.56-4-5.06C8 9.76 8.67 9 9.5 9c.75 0 1.5.45 1.5 1.45V11h2v-.55c0-1 .75-1.45 1.5-1.45.83 0 1.5.76 1.5 1.7 0 1.5-2.26 3.73-4 5.06zM12 0C5.38 0 0 5.38 0 12s5.38 12 12 12 12-5.38 12-12S18.62 0 12 0zm0 22C6.49 22 2 17.51 2 12S6.49 2 12 2s10 4.49 10 10-4.49 10-10 10z"
    ></path>
  </svg>
);
