import { signInWithCustomToken } from 'firebase/auth';
import { auth } from 'lib/firebase/firebase-config';

export async function verifyAndRefreshToken() {
  if (typeof window === 'undefined' || !window.crush?.token) return null;

  try {
    // Get current token from iOS bridge
    const currentToken = await window.crush.token();

    try {
      // Try to sign in with the token
      await signInWithCustomToken(auth, currentToken);
      window.location.reload(); // reload page after new signin.
      // If we get here, token is valid
      return true;
    } catch (error) {
      console.error('Failed to sign in with current token:', error);
      // Token is invalid, request a new one from iOS
      const newToken = await window.crush.token(); // This should trigger iOS to refresh the token

      if (newToken) {
        try {
          await signInWithCustomToken(auth, newToken);
          window.location.reload(); // reload page after new signin.
          return true;
        } catch (error) {
          console.error('Failed to sign in with new token:', error);
          //   window.crush.signout();
          return false;
        }
      } else {
        // If we couldn't get a new token, sign out
        // window.crush.signout();
        return false;
      }
    }
  } catch (error) {
    console.error('Token verification failed:', error);
    // window.crush.signout();
    return false;
  }
}