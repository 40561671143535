import { useEffect } from 'react';

type Props = {
  onClick?: () => void;
};

export const useDisableBack = ({ onClick }: Props) => {
  const handleBack = (e: PopStateEvent) => {
    e.preventDefault();
    window.history.pushState(null, document.title, location.href);
    onClick?.();
  };

  useEffect(() => {
    window.history.pushState(null, document.title, location.href);
    window.addEventListener('popstate', handleBack);
    return () => {
      window.removeEventListener('popstate', handleBack);
    };
  }, []);
};
