import { RefObject, useEffect } from 'react';
import { Chat } from 'store/chat';

export const scrollToBottom = (behavior: ScrollBehavior) => {
  const chatHolder = document.querySelector('.chatHolder');
  chatHolder?.scrollTo({
    top: chatHolder.scrollHeight,
    behavior
  });
};

export const useChatScroll = (conversationId: number, chatRef: RefObject<HTMLDivElement>, chat?: Chat) => {
  useEffect(() => {
    if ((chat?.messages?.length || 0) > 0 && conversationId === chat?.conversationId && chatRef.current) {
      const chatImages = Array.from(chatRef.current?.querySelectorAll<HTMLImageElement>('.botImage') || []);
      const last = chatImages[chatImages.length - 1];
      chatImages.length ? (last.onload = () => scrollToBottom('instant')) : scrollToBottom('instant');
    }
  }, [chat, conversationId, chatRef]);
};
