import { Close } from 'components/icons/Close';
import { ModalWrapper } from './ModalWrapper';

type Props = {
  children: React.ReactNode;
  onClose?: () => void;
  className?: string;
  nonDismissable?: boolean;
  bgImage?: string;
  contentWrappesClassName?: string;
};

export const Modal = ({
  children,
  onClose,
  className = '',
  nonDismissable,
  bgImage,
  contentWrappesClassName
}: Props) => (
  <ModalWrapper contentWrappesClassName={`w-[450px] max-w-[100%] ${contentWrappesClassName}`} onClose={onClose}>
    <div
      className={`w-full xl:w-[450px] relative bg-tertiary rounded-xl p-6 pt-14 ${className}`}
      style={
        bgImage
          ? {
              backgroundImage: `url("${bgImage}")`,
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }
          : {}
      }
    >
      {bgImage && <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-black/60" />}
      {!nonDismissable && (
        <Close
          className="cursor-pointer absolute top-4 right-4 w-6 h-6 opacity-50 hover:opacity-100"
          onClick={onClose}
        />
      )}

      <div className="z-20">{children}</div>
    </div>
  </ModalWrapper>
);
