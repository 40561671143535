import apiCall from 'api';
import { useAuthStore } from 'store/auth';
import { PostPurchasePayload } from './types';
import { reportBug } from 'utils';
import { showToast } from 'components/ui/CustomToast';

export const postBillingPackagePurchase = async ({ price_id, path, amount, user_id, use_saved = true }: PostPurchasePayload) => {
  try {
    const request = await apiCall();
    const analyticsParams = `user_id=${user_id}&package_id=${price_id}&amount=${amount}`;

    const origin = window?.location.origin;

    // Success URL:
    //   Subscription: /subscriptions/success
    //   Credits: /credits?credits=success
    const redirect_url =
      path === 'billing'
        ? `${origin}/subscriptions/success?${analyticsParams}`
        : `${origin}/?credits=success&${analyticsParams}`;

    // Cancel URL:
    //   Subscription: /subscriptions?billing=cancel
    //   Credits: /credits?credits=cancel
    const cancel_url =
      path === 'billing'
        ? `${origin}/subscriptions?billing=cancel&${analyticsParams}`
        : `${origin}/credits?credits=cancel&${analyticsParams}`;

    const { data } = await request({
      url: `billing/packages/${price_id}/purchase?saved_method=${use_saved}`,
      method: 'POST',
      data: {
        redirect_url,
        cancel_url
      }
    });

    if (data.data && data.data.url) {
      return data.data.url as string;
    } else if (data.data === undefined) {
      return null;
    }
  } catch (err: any) {
    reportBug({ msg: err?.data?.message, error: err, prodToast: true });
    return '';
  }
};

export const fetchBillingSubscriptions = async () => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: 'billing/subscriptions', method: 'GET' });

    if (data.data.length) useAuthStore.getState().setSubscription(data.data[0]);
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return null;
  }
};

export const cancelBillingSubscription = async () => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `billing/subscriptions/cancel`,
      method: 'POST'
    });

    showToast({ message: 'Subscription successfully cancelled.', type: 'success' });
    useAuthStore.getState().setSubscription(data.data);
  } catch (err: any) {
    showToast({ message: 'Something went wrong, please contact support.', type: 'error' });
    reportBug({ msg: err?.data?.message, error: err });
    return null;
  }
};

export const resumeSubscription = async () => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `billing/subscriptions/resume`,
      method: 'POST'
    });

    showToast({ message: 'Subscription successfully resumed.', type: 'success' });
    useAuthStore.getState().setSubscription(data.data);
  } catch (err: any) {
    showToast({ message: 'Something went wrong, please contact support.', type: 'error' });
    reportBug({ msg: err?.data?.message, error: err });
    return null;
  }
};
