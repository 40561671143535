import { IconProps } from 'utils/global.types';

export const Logout = (props?: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" data-name="Layer 1" viewBox="0 0 24 24" {...props}>
    <path
      fill="white"
      d="M22.763 10.232l-4.95-4.95L16.4 6.7l4.3 4.3H6.617v2H20.7l-4.3 4.3 1.414 1.414 4.95-4.95a2.5 2.5 0 000-3.536z"
    ></path>
    <path
      fill="white"
      d="M10.476 21a1 1 0 01-1 1H3a1 1 0 01-1-1V3a1 1 0 011-1h6.476a1 1 0 011 1v5.333h2V3a3 3 0 00-3-3H3a3 3 0 00-3 3v18a3 3 0 003 3h6.476a3 3 0 003-3v-5.333h-2z"
    ></path>
  </svg>
);
