import { create } from 'zustand';

import { Subscription } from 'api/billing/types';
import { User } from 'api/user/types';

interface AuthState {
  user: User | null;
  setUser: (user: User | null) => void;

  isLogged: boolean;
  setIsLogged: (isLogged: boolean) => void;

  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;

  subscription?: Subscription;
  setSubscription: (subscription: Subscription) => void;

  logoutRedirect: string;
  setLogoutRedirect: (logoutType: string) => void;
}

export const useAuthStore = create<AuthState>((set) => ({
  user: null,
  setUser: (user) => set({ user }),

  isLogged: false,
  setIsLogged: (isLogged) => set({ isLogged }),

  isLoading: true,
  setIsLoading: (isLoading) => set({ isLoading }),

  subscription: undefined,
  setSubscription: (subscription) => set({ subscription }),

  logoutRedirect: '',
  setLogoutRedirect: (logoutRedirect) => set({ logoutRedirect })
}));
