import { icons } from 'components/icons/Toast';
import { ToastOptions, toast } from 'react-toastify';

type Props = {
  title?: string;
  message: string;
  type: keyof typeof icons;
  button?: JSX.Element;
  options?: ToastOptions;
};

export const CustomToast = ({ title, message, type, button }: Props) => {
  const assets = icons[type];

  return (
    <div className="flex items-center gap-2 min-h-full p-3 bg-[#242C32]">
      {assets.shadow({ className: 'size-5 left-0 bottom-1 absolute h-full w-[70%]' })}
      <div className="flex items-center justify-center">{assets.icon({ className: 'size-10' })}</div>
      <div className="flex items-start justify-center flex-col">
        <p className="text-lg font-semibold">{title}</p>
        <p className="text-md text-[#C8C5C5]">{message}</p>
      </div>
      {button}
    </div>
  );
};

export const showToast = ({ options, ...rest }: Props) => {
  const background = (() => {
    switch (rest.type) {
      case 'error':
        return '#FF5252';

      case 'warning':
        return '#FFC107';

      case 'success':
        return '#4CAF50';

      default:
        return '#FFC107';
    }
  })();

  return toast(<CustomToast {...rest} />, { ...options, progressStyle: { background } });
};
