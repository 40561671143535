import { useEffect } from 'react';
import { useAuthStore } from 'store/auth';
import { getStorageItem, setStorageItem } from 'utils/storage';
import { ReadonlyURLSearchParams, useSearchParams } from 'next/navigation';
import { isCrushAppMobile, isCrushAppIOS, isCrushAppAndroid } from 'utils/userAgentCheck';

export const useComputeRefer = (): void => {
  const queryParams = useSearchParams();
  const { isLogged, isLoading } = useAuthStore();

  useEffect(() => {
    if (isLogged || isLoading) return;

    const referDomain =
      document.referrer && !document.referrer.includes('firebaseapp.com') ? new URL(document.referrer).hostname : null;

    const existingRefer = getStorageItem('refer');

    if (existingRefer) {
      // update previously computed refer info
      if (existingRefer.source !== 'user_referral') {
        let referSource;
        let referId;

        if (existingRefer.source === 'affiliate') {
          if (queryParams.get('utm_source') === 'user_referral') {
            referSource = 'user_referral';
            referId = queryParams.get('utm_content');
          } else return;
        } else if (referDomain || existingRefer.source === 'direct') {
          const { referSource: newReferSource, referId: newReferId } = computeRefer(referDomain, queryParams);
          referSource = newReferSource;
          referId = newReferId;
        } else return;

        if (process.env.VERCEL_ENV !== 'production')
          console.log({ referSource, referId, referDomain });

        setStorageItem('refer', {
          source: referSource,
          id: referId,
          domain: referDomain || '(blank)',
          domain_initial: existingRefer.domain_initial, // persist initial referrer
          initial_path: existingRefer.initial_path
        });
      }
    } else {
      // compute the refer info for the first time
      const { referSource, referId } = computeRefer(referDomain, queryParams);

      const fullPath = window.location.pathname + window.location.search;
      const truncatedPath = fullPath.substring(0, 150);

      if (process.env.VERCEL_ENV !== 'production')
        console.log({ referSource, referId, referDomain });

      setStorageItem('refer', {
        source: referSource,
        id: referId,
        domain: referDomain || '(blank)',
        domain_initial: referDomain || '(blank)',
        initial_path: truncatedPath
      });
    }
  }, [queryParams, isLogged, isLoading]);
};

interface ComputeResult {
  referSource: string | null;
  referId: string | null;
}

function computeRefer(referDomain: string | null, query: ReadonlyURLSearchParams): ComputeResult {
  let referSource;
  let referId;

  const isAppIOS = isCrushAppIOS();
  const isAppAndroid = isCrushAppAndroid();
  const utm_source = query.get('utm_source');
  const utm_campaign = query.get('utm_campaign');
  const utm_medium = query.get('ref') || query.get('via') || query.get('utm_medium')
  const fpr_affiliate_id = query.get('fpr');

  if (fpr_affiliate_id) {
    referSource = 'affiliate';
    referId = fpr_affiliate_id;
  } else if (isAppIOS) {
    referSource = 'ios';
  } else if (isAppAndroid) {
    referSource = 'android';
  } else if (utm_source === 'user_referral') {
    referSource = 'user_referral';
    referId = query.get('utm_content');
  } else if (utm_source === 'affiliate') {
    referSource = 'affiliate';
    referId = utm_medium;
  } else if (query.get('gad_source') === '1') {
    referSource = 'google-sem';
    referId = utm_campaign;
  } else if (utm_source === 'ja') {
    referSource = 'juicy';
    referId = utm_campaign;
  } else if (utm_source === 'referral' || utm_medium) {
    referSource = 'referral';
    referId = utm_medium;
  } else if (referDomain) {
    referSource = referDomain.includes('google') ? 'google-seo' : 'referral';
    referId = null;
  } else {
    referSource = 'direct';
    referId = null;
  }

  return {
    referSource,
    referId: (referId as string | null) || null
  };
}
