import { ClipLoader } from "react-spinners";
import { useAuthStore } from 'store/auth';
import { useEffect, useState } from 'react';
import { reportAnalyticsEvent } from 'utils/analytics';
import { verifyAndRefreshToken } from 'utils/tokenVerification';

export const AuthHolder = () => {
    const [showReload, setShowReload] = useState(false);
    const { user, isLoading } = useAuthStore();
    const [isRetrying, setIsRetrying] = useState(false);

    useEffect(() => {
      const retryAuth = async () => {
        if (!user && !isRetrying) {
          try {
            setIsRetrying(true);
            await verifyAndRefreshToken();
          } finally {
            setIsRetrying(false);
          }
        }
      };

      const retryInterval = setInterval(retryAuth, 2000); // Try every 2 seconds

      // Run once immediately
    //   void retryAuth();

      // Cleanup interval when component unmounts or user is loaded
      return () => clearInterval(retryInterval);
    }, [user, isRetrying]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!user) {
                reportAnalyticsEvent('login_error');
                setShowReload(true);
            }
        }, 10000);

        return () => clearTimeout(timer);
    }, [user]);

    if (showReload && !user) {
        return (
            <div className="h-screen flex flex-col gap-4 items-center justify-center fixed inset-0 z-50 bg-black bg-opacity-80">
                <p className="text-white text-lg">Oops, your session expired.</p>
                <button
                    onClick={async () => {
                        if (typeof window !== 'undefined' && window.crush) {
                            await verifyAndRefreshToken();
                        }
                        window.location.reload()
                    }}
                    className="px-4 py-2 bg-brand text-white rounded"
                >
                    Refresh Session
                </button>
                <button
                    className="btn outlined secondary"
                    onClick={() => {
                        if (typeof window !== 'undefined' && window.crush) {
                            window.crush.signout();
                        }
                    }}
                >
                    Sign Out
                </button>
            </div>
        );
    }

    return !user && (
        <div className="h-screen flex items-center justify-center z-50 bg-black bg-opacity-80 fixed inset-0">
            <ClipLoader size={48} speedMultiplier={0.5} color="#AAA" />
        </div>
    );
};