import { useEffect, useState } from 'react';

export const useIsPWA = () => {
  const [isPWA, setIsPWA] = useState(false);

  const isStandalone = () =>
    window.matchMedia('(display-mode: standalone)').matches || (window.navigator as any).standalone;

  useEffect(() => {
    const checkPWA = () => setIsPWA(isStandalone());

    checkPWA();
    const handleChange = () => checkPWA();
    window.matchMedia('(display-mode: standalone)').addEventListener('change', handleChange);

    return () => {
      window.matchMedia('(display-mode: standalone)').removeEventListener('change', handleChange);
    };
  }, []);

  return { isPWA, isStandalone };
};
