import { IconProps } from 'utils/global.types';

export const Explore = (props?: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" data-name="Layer 1" viewBox="0 0 24 24" {...props}>
    <path
      fill="white"
      d="M20.466 1.967L14.78.221a5.011 5.011 0 00-6.224 3.24L8.368 4H5a5.006 5.006 0 00-5 5v10a5.006 5.006 0 005 5h6a4.975 4.975 0 003.92-1.934 5.029 5.029 0 00.689.052 4.976 4.976 0 004.775-3.563L23.8 8.156a5.021 5.021 0 00-3.334-6.189zM11 22H5a3 3 0 01-3-3V9a3 3 0 013-3h6a3 3 0 013 3v10a3 3 0 01-3 3zM21.887 7.563l-3.412 10.4a2.992 2.992 0 01-2.6 2.134A4.992 4.992 0 0016 19V9a5.006 5.006 0 00-5-5h-.507a3 3 0 013.7-1.867l5.686 1.746a3.006 3.006 0 012.008 3.684zM12 13c0 1.45-1.544 3.391-2.714 4.378a1.991 1.991 0 01-2.572 0C5.544 16.391 4 14.45 4 13a2 2 0 014 0 2 2 0 014 0z"
    ></path>
  </svg>
);
