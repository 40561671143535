import { create } from 'zustand';

interface ModalsState {
  isOpenAuthModal: boolean | string;
  setIsOpenAuthModal: (isOpenAuthModal: boolean | string) => void;

  upsellModal: {
    image: string;
    type: 'out_of_credit' | 'premium_profile' | 'premium_photo' | 'premium_voice' | null;
    showRefreshCountdown?: boolean;
  };
  setUpsellModal: (upsellModal: ModalsState['upsellModal']) => void;

  creditsUpsellModal: string | null;
  setCreditsUpsellModal: (creditsUpsellModal: string | null) => void;
}

export const useModalsStore = create<ModalsState>((set) => ({
  isOpenAuthModal: false,
  setIsOpenAuthModal: (isOpenAuthModal) => set({ isOpenAuthModal }),

  upsellModal: {
    image: '',
    type: null,
    showRefreshCountdown: false
  },
  setUpsellModal: (upsellModal) => set({ upsellModal }),

  creditsUpsellModal: null,
  setCreditsUpsellModal: (creditsUpsellModal) => set({ creditsUpsellModal })
}));
