import { IconProps } from 'utils/global.types';

const ToastWarningIcon = (props?: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32" {...props}>
    <rect width="32" height="32" fill="#303746" rx="16"></rect>
    <g clipPath="url(#clip0_102_11)">
      <path
        fill="#FFD21E"
        d="M16 6C10.48 6 6 10.48 6 16s4.48 10 10 10 10-4.48 10-10S21.52 6 16 6zm1 15h-2v-2h2v2zm0-4h-2v-6h2v6z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_102_11">
        <path fill="#fff" d="M0 0H24V24H0z" transform="translate(4 4)"></path>
      </clipPath>
    </defs>
  </svg>
);

const ToastWarningShadow = (props?: IconProps) => (
  <svg width="138" height="82" viewBox="0 0 138 82" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="32" cy="41" r="106" fill="url(#paint0_radial_102_19)" fill-opacity="0.11" />
    <defs>
      <radialGradient
        id="paint0_radial_102_19"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(32 41) rotate(90) scale(106)"
      >
        <stop stop-color="#FFD426" />
        <stop offset="1" stop-color="#FFD426" stop-opacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

const ToastSuccessIcon = (props?: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32" {...props}>
    <rect width="32" height="32" fill="#303746" rx="16"></rect>
    <mask id="mask0_102_2" style={{ maskType: 'alpha' }} width="24" height="24" x="4" y="4" maskUnits="userSpaceOnUse">
      <path fill="#D9D9D9" d="M4 4H28V28H4z"></path>
    </mask>
    <g mask="url(#mask0_102_2)">
      <path
        fill="#00DF80"
        d="M14.6 20.6l7.05-7.05-1.4-1.4-5.65 5.65-2.85-2.85-1.4 1.4 4.25 4.25zM16 26a9.733 9.733 0 01-3.9-.788 10.092 10.092 0 01-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.733 9.733 0 016 16c0-1.383.263-2.683.788-3.9a10.091 10.091 0 012.137-3.175c.9-.9 1.958-1.613 3.175-2.138A9.743 9.743 0 0116 6c1.383 0 2.683.262 3.9.787a10.105 10.105 0 013.175 2.138c.9.9 1.612 1.958 2.137 3.175A9.733 9.733 0 0126 16a9.733 9.733 0 01-.788 3.9 10.092 10.092 0 01-2.137 3.175c-.9.9-1.958 1.612-3.175 2.137A9.733 9.733 0 0116 26z"
      ></path>
    </g>
  </svg>
);

const ToastSuccessShadow = (props: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="138" height="82" fill="none" viewBox="0 0 138 82" {...props}>
    <circle cx="32" cy="41" r="106" fill="url(#paint0_radial_102_10)" fillOpacity="0.12"></circle>
    <defs>
      <radialGradient
        id="paint0_radial_102_10"
        cx="0"
        cy="0"
        r="1"
        gradientTransform="matrix(0 106 -106 0 32 41)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00ED51"></stop>
        <stop offset="1" stopColor="#00ED7B" stopOpacity="0"></stop>
      </radialGradient>
    </defs>
  </svg>
);

const ToastErrorIcon = (props?: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32" {...props}>
    <rect width="32" height="32" fill="#303746" rx="16"></rect>
    <mask id="mask0_102_20" style={{ maskType: 'alpha' }} width="24" height="24" x="4" y="4" maskUnits="userSpaceOnUse">
      <path fill="#D9D9D9" d="M4 4H28V28H4z"></path>
    </mask>
    <g mask="url(#mask0_102_20)">
      <path
        fill="#F04248"
        d="M12.4 21l3.6-3.6 3.6 3.6 1.4-1.4-3.6-3.6 3.6-3.6-1.4-1.4-3.6 3.6-3.6-3.6-1.4 1.4 3.6 3.6-3.6 3.6 1.4 1.4zm3.6 5a9.733 9.733 0 01-3.9-.788 10.092 10.092 0 01-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.733 9.733 0 016 16c0-1.383.263-2.683.788-3.9a10.091 10.091 0 012.137-3.175c.9-.9 1.958-1.613 3.175-2.138A9.743 9.743 0 0116 6c1.383 0 2.683.262 3.9.787a10.105 10.105 0 013.175 2.138c.9.9 1.612 1.958 2.137 3.175A9.733 9.733 0 0126 16a9.733 9.733 0 01-.788 3.9 10.092 10.092 0 01-2.137 3.175c-.9.9-1.958 1.612-3.175 2.137A9.733 9.733 0 0116 26z"
      ></path>
    </g>
  </svg>
);

const ToastErrorShadow = (props: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="152" height="64" fill="none" viewBox="0 0 152 64" {...props}>
    <circle cx="32" cy="32" r="120" fill="url(#paint0_radial_102_28)" fillOpacity="0.13"></circle>
    <defs>
      <radialGradient
        id="paint0_radial_102_28"
        cx="0"
        cy="0"
        r="1"
        gradientTransform="rotate(90 0 32) scale(120)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F04248"></stop>
        <stop offset="1" stopColor="#F04248" stopOpacity="0"></stop>
      </radialGradient>
    </defs>
  </svg>
);

export const icons = {
  warning: { icon: ToastWarningIcon, shadow: ToastWarningShadow },
  success: { icon: ToastSuccessIcon, shadow: ToastSuccessShadow },
  error: { icon: ToastErrorIcon, shadow: ToastErrorShadow }
};
