import { createPortal } from 'react-dom';

interface Props {
  children: React.ReactNode;
  contentWrappesClassName?: string;
  backgroundClassName?: string;
  closeOnClick?: boolean;
  onClose?: () => void;
}

export const ModalWrapper = ({
  children,
  onClose,
  contentWrappesClassName,
  closeOnClick,
  backgroundClassName
}: Props) => (
  <>
    {createPortal(
      <div
        className={`fixed inset-0 z-[999] flex items-center justify-center p-3 backdrop-blur-sm bg-opacity-50 ${backgroundClassName}`}
        onClick={onClose}
      >
        <div
          className={contentWrappesClassName}
          onClick={(e) => {
            e.stopPropagation();
            closeOnClick && onClose?.();
          }}
        >
          {children}
        </div>
      </div>,
      document.body
    )}
  </>
);
